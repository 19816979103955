// React imports
import type { ReactElement } from 'react'
import React from 'react'

// Remix imports

// Third party imports

// Generated imports
import type {
  ArtConsultancyBannerFragment,
  BannerFragment,
  Maybe,
} from '~/generated/graphql'

// App imports
import useImagekit from '~/hooks/useImagekit'
import Carousel from './Carousel'

interface Props {
  className?: string
  data: Array<Maybe<BannerFragment | ArtConsultancyBannerFragment>>
}

const BannerCarousel: React.FC<Props> = ({ className, data }) => {
  const { imagekitUrl } = useImagekit()

  const renderSlide = (
    item: Maybe<BannerFragment | ArtConsultancyBannerFragment>,
    index: number
  ) => {
    return (
      <React.Fragment>
        <img
          className="hidden object-cover md:block"
          key={`desktop-${index}`}
          alt={item?.description || `Slide #${index + 1}`}
          src={imagekitUrl(item?.originalImage, '?tr=w-2220')}
        />
        <img
          className="block object-cover md:hidden"
          key={`mobile-${index}`}
          alt={item?.description || `Slide #${index + 1}`}
          src={imagekitUrl(item?.originalImageMobile, '?tr=w-1424')}
        />
        {item?.description && (
          <span className="absolute bottom-[40px] left-[30px] block max-w-[66%] pr-4 font-serif text-h2 text-white md:max-w-[45%] md:text-h2md ">
            {item.description}
          </span>
        )}
      </React.Fragment>
    )
  }

  const slides: ReactElement[] = []
  data.forEach((item, index) => {
    if (item?.originalImage) {
      if (item?.link) {
        slides.push(
          <a
            href={item.link}
            className={
              'absolute' +
              (item.link ? ' cursor-pointer hover:opacity-hover' : '')
            }
          >
            {renderSlide(item, index)}
          </a>
        )
      } else {
        slides.push(renderSlide(item, index))
      }
    }
  })
  return (
    <Carousel
      className={className}
      slides={slides}
      aspectRatio="md:aspect-1110/448"
      aspectRatioMobile="aspect-712/876"
    />
  )
}

export default BannerCarousel
